<template>
  <v-container fluid>
    <v-row class="mt-5 mx-150 justify-content-center">
      <v-col cols="12" class="my-50">
        <span class="mb-5">{{$t("addMemberToOrg")}}</span>
        <h3 class="mb-5">{{$t("inviteMembers")}}</h3>
      </v-col>
      <v-col lg="5" md="7">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }">
          <v-form role="newInvite" @submit.prevent="handleSubmit(newInvite)">
            <v-text-field
              class="mb-3"
              name="inviteEmail"
              type="email"
              :label="$t('inputEmail')"
              rules="required|email"
              v-model="inviteEmail"
              id="inviteEmail"
              outlined
              dense
            ></v-text-field>
            <v-select v-model="selected" :items="options" label="Role" id="role"></v-select>
            <div class="text-center mt-50">
              <v-btn
                :disabled="invalid"
                block
                color="primary"
                id="send-invite"
                type="submit"
                class="my-4"
              >{{$t("sendInvite")}}</v-btn>
            </div>
          </v-form>
        </ValidationObserver>
        <v-btn
          block
          color="primary"
          v-on:click="skipInvite"
          id="skip-invite"
          type="button"
          class="my-4"
        >{{$t("skipNow")}}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import makeOrgService from '@/services/api/org';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      orgName: '',
      inviteEmail: '',
      selected: null,
      options: [],
    };
  },
  created() {
    let org = this.getOrg(this.$route.params.handle);
    const orgService = makeOrgService(this.$api);
    orgService.getRoles(org.uid).then(response => {
      this.options = response.data.roles.map(element => {
        return {
          value: element.uid,
          text: element.name,
        };
      });
      this.selected = this.options[0].value;
    });
  },
  computed: {
    ...mapGetters({
      getOrg: 'user/getOrg',
    }),
  },
  methods: {
    newInvite() {
      const inviteEmail = this.inviteEmail;
      const handle = this.$route.params.handle;
      const role = this.selected;
      const orgService = makeOrgService(this.$api);
      orgService
        .newInvite({ inviteEmail, handle, role })
        .then(() => {
          this.$swal({
            title: this.$t('invite link sent'),
            icon: 'success',
            showConfirmButton: false,
            position: 'top-center',
            timer: 2000,
            toast: true,
          }).then(() => {
            this.$router.push('/');
          });
        })
        .catch(error => {
          this.$swal({
            title: this.$t('error'),
            text: error,
            icon: 'error',
            showConfirmButton: false,
            position: 'top-center',
            timer: 2000,
            toast: true,
          });
        });
    },
    skipInvite() {
      this.$router.push('/');
    },
  },
};
</script>